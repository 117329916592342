<ng-container>
  <front-content>
    <front-header
      [title]="'About us'"
    />

    <front-section>
      About service
    </front-section>

  </front-content>
</ng-container>
