import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MenuFrontComponent } from '../menu-front/menu-front.component';

@Component({
  selector: 'front-container',
  standalone: true,
  imports: [RouterOutlet, MenuFrontComponent],
  templateUrl: './front-container.component.html',
  styleUrl: './front-container.component.scss',
})
export class FrontContainerComponent implements OnInit, OnChanges {
  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {}
}
