<ng-container>
  <front-content>
    <front-header
      [title]="'News'"
    />

    <front-section>
      News service
    </front-section>

  </front-content>
</ng-container>
