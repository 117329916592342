import { Routes } from '@angular/router';
import { HomeComponent } from './modules/front/modules/home/views/home/home.component';
import { authCanActivateHash, authPathMatcher } from '@ckmk/angular';
import { environment } from '../environments/environment';
import { LoginComponent } from './modules/admin/views/login/login.component';
import { DashboardComponent } from './modules/admin/views/dashboard/dashboard.component';
import { NotFoundComponent } from './modules/front/modules/not-found/views/home/not-found.component';
import { ServicesComponent } from './modules/front/modules/services/views/home/services.component';
import { SolutionsComponent } from './modules/front/modules/solutions/views/home/solutions.component';
import { AboutComponent } from './modules/front/modules/about/views/home/about.component';
import { NewsComponent } from './modules/front/modules/news/views/home/news.component';
import { RseComponent } from './modules/front/modules/rse/views/home/rse.component';
import { ContactComponent } from './modules/front/modules/contact/views/home/contact.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'solutions', component: SolutionsComponent },
  { path: 'news', component: NewsComponent },
  { path: 'rse', component: RseComponent },
  { path: 'contact', component: ContactComponent },
  {
    matcher: authPathMatcher(),
    canActivate: [authCanActivateHash(environment.ADMIN_HASH)],
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
    ],
  },
  { path: '**', component: NotFoundComponent },
];
