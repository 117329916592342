import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { PlatformIdService } from '@ckmk/angular';
import { NgClass } from '@angular/common';
import { RemoteMenuItems } from '../../../../types/types';
import { MenuItemGroupComponent } from '../menu-item-group/menu-item-group.component';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'menu-front',
  standalone: true,
  imports: [
    FaIconComponent,
    NgClass,
    MenuItemGroupComponent,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './menu-front.component.html',
  styleUrl: './menu-front.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MenuFrontComponent implements OnInit, OnChanges {
  protected siteName: string = environment.SITE_NAME;

  protected icons = {
    bars: faBars,
    close: faXmark,
  };

  protected opened: boolean = false;

  protected solutions: RemoteMenuItems | null = null;

  protected services: RemoteMenuItems | null = null;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _platformIdService: PlatformIdService,
  ) {}

  public ngOnInit() {
    this._loadSolutions();

    this._loadServices();
  }

  public ngOnChanges(changes: SimpleChanges): void {}

  public toggleOpened(): void {
    this.opened = !this.opened;

    if (this._platformIdService.isBrowser()) {
      if (this.opened) {
        document.body.classList.add('overflow-y-hidden');
      } else {
        document.body.classList.remove('overflow-y-hidden');
      }
    }
  }

  protected isOpened(): boolean {
    return (
      this.opened ||
      (this._platformIdService.isBrowser() && document.body.offsetWidth >= 768)
    );
  }

  protected _loadSolutions(): void {
    Promise.resolve().then(() => {
      this.solutions = [
        {
          title: 'Solution 1',
          link: '#',
        },
        {
          title: 'Solution 2',
          link: '#',
        },
        {
          title: 'Solution 3',
          link: '#',
        },
        {
          title: 'Solution 4',
          link: '#',
        },
      ];
    });
  }

  protected _loadServices(): void {
    Promise.resolve().then(() => {
      this.services = [
        {
          title: 'Maintenance',
          link: '#',
        },
        {
          title: 'Formation',
          link: '#',
        },
      ];
    });
  }
}
